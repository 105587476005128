@use '@angular/material' as mat;
@use './stylesm3-theme';

@include mat.core();

:root {
  // @include mat.all-component-themes(stylesm3-theme.$light-theme);
  @include mat.core-theme(stylesm3-theme.$light-theme);
  @include mat.button-theme(stylesm3-theme.$light-theme);
  @include mat.icon-button-theme(stylesm3-theme.$light-theme);
  @include mat.icon-theme(stylesm3-theme.$light-theme);
  @include mat.input-theme(stylesm3-theme.$light-theme);
  @include mat.table-theme(stylesm3-theme.$light-theme);
  @include mat.autocomplete-theme(stylesm3-theme.$light-theme);
  @include mat.checkbox-theme(stylesm3-theme.$light-theme);
  @include mat.dialog-theme(stylesm3-theme.$light-theme);
  @include mat.select-theme(stylesm3-theme.$light-theme);
  @include mat.paginator-theme(stylesm3-theme.$light-theme);
  @include mat.form-field-theme(stylesm3-theme.$light-theme);
  @include mat.datepicker-theme(stylesm3-theme.$light-theme);
  @include mat.progress-spinner-theme(stylesm3-theme.$light-theme);
  @include mat.menu-theme(stylesm3-theme.$light-theme);
  @include mat.tooltip-theme(stylesm3-theme.$light-theme);
  @include mat.sidenav-theme(stylesm3-theme.$light-theme);
  @include mat.chips-theme(stylesm3-theme.$light-theme);
  @include mat.form-field-density(-3)
}

.primary-chip {
  @include mat.chips-color(stylesm3-theme.$light-theme, $color-variant: primary);
}

.primary-icon {
  @include mat.icon-color(stylesm3-theme.$light-theme, $color-variant: primary);
  opacity: .5;
}

.tertiary-icon {
  @include mat.icon-color(stylesm3-theme.$light-theme, $color-variant: tertiary);
}